<template>
    <div class="index">
        <div class="ls_flex">
            <a v-for="item in menu" :key="item.name" :target="item.isOut ? '_blank' : ''" :href="item.route">{{ item.name }}</a>
        </div>

        <div class="img_list">
            <img src="@/assets/imgs/houtai_zuopshuju.png" />
            <img src="@/assets/imgs/houtai_yonghushuju.png" />
            <img src="@/assets/imgs/houtai_kejianshuju.png" />
            <img src="@/assets/imgs/houtai_bisaishuju.png" />
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            menu: [
                {
                    name: "插花练习",
                    route: this.$editAddr,
                    isOut: true,
                },
                {
                    name: "插花比赛",
                    route: "/#/race",
                },
                {
                    name: "教学课件",
                    route: "/#/courseware",
                },
                {
                    name: "插花作业",
                    route: "/#/homework",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.index {
    padding: 20px 20px 0 0;

    a {
        flex: 1;
        padding: 35px 0;
        border-radius: 5px;
        border: 1px solid #8bb229;
        text-align: center;
        font-size: 22px;
        line-height: 30px;
        color: #fff;
        background: #8bb229;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

        &:hover {
            background: #9bc434;
            color: #fff;
        }

        +a {
            margin-left: 35px;
        }
    }
}

.img_list {
    margin-top: 33px;
    overflow: hidden;
    width: 1114px;
    margin: 33px auto 0 auto;

    >img {
        float: left;
        width: 540px;
        margin-bottom: 33px;

        &:nth-child(2n + 1) {
            margin-right: 33px;
        }
    }
}
</style>